@font-face {
    font-family: "uicons-bold-rounded";
    src: url("./webfonts/uicons-bold-rounded.eot#iefix") format("embedded-opentype"),
url("./webfonts/uicons-bold-rounded.woff2") format("woff2"),
url("./webfonts/uicons-bold-rounded.woff") format("woff");
}

i[class^="fi-br-"]:before, i[class*="fi-br-"]:before, span[class^="fi-br-"]:before, span[class*="fi-br-"]:before {
    font-family: uicons-bold-rounded !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fi-br-add:before {
    content: "\f101";
}
.fi-br-alarm-clock:before {
    content: "\f102";
}
.fi-br-align-center:before {
    content: "\f103";
}
.fi-br-align-justify:before {
    content: "\f104";
}
.fi-br-align-left:before {
    content: "\f105";
}
.fi-br-align-right:before {
    content: "\f106";
}
.fi-br-ambulance:before {
    content: "\f107";
}
.fi-br-angle-double-left:before {
    content: "\f108";
}
.fi-br-angle-double-right:before {
    content: "\f109";
}
.fi-br-angle-double-small-left:before {
    content: "\f10a";
}
.fi-br-angle-double-small-right:before {
    content: "\f10b";
}
.fi-br-angle-down:before {
    content: "\f10c";
}
.fi-br-angle-left:before {
    content: "\f10d";
}
.fi-br-angle-right:before {
    content: "\f10e";
}
.fi-br-angle-small-down:before {
    content: "\f10f";
}
.fi-br-angle-small-left:before {
    content: "\f110";
}
.fi-br-angle-small-right:before {
    content: "\f111";
}
.fi-br-angle-small-up:before {
    content: "\f112";
}
.fi-br-angle-up:before {
    content: "\f113";
}
.fi-br-apps-add:before {
    content: "\f114";
}
.fi-br-apps-delete:before {
    content: "\f115";
}
.fi-br-apps-sort:before {
    content: "\f116";
}
.fi-br-apps:before {
    content: "\f117";
}
.fi-br-archive:before {
    content: "\f118";
}
.fi-br-arrow-down:before {
    content: "\f119";
}
.fi-br-arrow-left:before {
    content: "\f11a";
}
.fi-br-arrow-right:before {
    content: "\f11b";
}
.fi-br-arrow-small-down:before {
    content: "\f11c";
}
.fi-br-arrow-small-left:before {
    content: "\f11d";
}
.fi-br-arrow-small-right:before {
    content: "\f11e";
}
.fi-br-arrow-small-up:before {
    content: "\f11f";
}
.fi-br-arrow-up:before {
    content: "\f120";
}
.fi-br-asterisk:before {
    content: "\f121";
}
.fi-br-backpack:before {
    content: "\f122";
}
.fi-br-badge:before {
    content: "\f123";
}
.fi-br-ban:before {
    content: "\f124";
}
.fi-br-band-aid:before {
    content: "\f125";
}
.fi-br-bank:before {
    content: "\f126";
}
.fi-br-barber-shop:before {
    content: "\f127";
}
.fi-br-baseball:before {
    content: "\f128";
}
.fi-br-basketball:before {
    content: "\f129";
}
.fi-br-bell-ring:before {
    content: "\f12a";
}
.fi-br-bell-school:before {
    content: "\f12b";
}
.fi-br-bell:before {
    content: "\f12c";
}
.fi-br-billiard:before {
    content: "\f12d";
}
.fi-br-bold:before {
    content: "\f12e";
}
.fi-br-book-alt:before {
    content: "\f12f";
}
.fi-br-book:before {
    content: "\f130";
}
.fi-br-bookmark:before {
    content: "\f131";
}
.fi-br-bowling:before {
    content: "\f132";
}
.fi-br-box-alt:before {
    content: "\f133";
}
.fi-br-box:before {
    content: "\f134";
}
.fi-br-briefcase:before {
    content: "\f135";
}
.fi-br-broom:before {
    content: "\f136";
}
.fi-br-browser:before {
    content: "\f137";
}
.fi-br-brush:before {
    content: "\f138";
}
.fi-br-bug:before {
    content: "\f139";
}
.fi-br-building:before {
    content: "\f13a";
}
.fi-br-bulb:before {
    content: "\f13b";
}
.fi-br-calculator:before {
    content: "\f13c";
}
.fi-br-calendar:before {
    content: "\f13d";
}
.fi-br-camera:before {
    content: "\f13e";
}
.fi-br-caret-down:before {
    content: "\f13f";
}
.fi-br-caret-left:before {
    content: "\f140";
}
.fi-br-caret-right:before {
    content: "\f141";
}
.fi-br-caret-up:before {
    content: "\f142";
}
.fi-br-check:before {
    content: "\f143";
}
.fi-br-checkbox:before {
    content: "\f144";
}
.fi-br-chess:before {
    content: "\f145";
}
.fi-br-circle-small:before {
    content: "\f146";
}
.fi-br-circle:before {
    content: "\f147";
}
.fi-br-clip:before {
    content: "\f148";
}
.fi-br-clock:before {
    content: "\f149";
}
.fi-br-cloud-check:before {
    content: "\f14a";
}
.fi-br-cloud-disabled:before {
    content: "\f14b";
}
.fi-br-cloud-download:before {
    content: "\f14c";
}
.fi-br-cloud-share:before {
    content: "\f14d";
}
.fi-br-cloud-upload:before {
    content: "\f14e";
}
.fi-br-cloud:before {
    content: "\f14f";
}
.fi-br-comment-alt:before {
    content: "\f150";
}
.fi-br-comment:before {
    content: "\f151";
}
.fi-br-compress-alt:before {
    content: "\f152";
}
.fi-br-compress:before {
    content: "\f153";
}
.fi-br-computer:before {
    content: "\f154";
}
.fi-br-cookie:before {
    content: "\f155";
}
.fi-br-copy-alt:before {
    content: "\f156";
}
.fi-br-copy:before {
    content: "\f157";
}
.fi-br-copyright:before {
    content: "\f158";
}
.fi-br-cream:before {
    content: "\f159";
}
.fi-br-credit-card:before {
    content: "\f15a";
}
.fi-br-cross-circle:before {
    content: "\f15b";
}
.fi-br-cross-small:before {
    content: "\f15c";
}
.fi-br-cross:before {
    content: "\f15d";
}
.fi-br-crown:before {
    content: "\f15e";
}
.fi-br-cursor-finger:before {
    content: "\f15f";
}
.fi-br-cursor-plus:before {
    content: "\f160";
}
.fi-br-cursor-text-alt:before {
    content: "\f161";
}
.fi-br-cursor-text:before {
    content: "\f162";
}
.fi-br-cursor:before {
    content: "\f163";
}
.fi-br-dart:before {
    content: "\f164";
}
.fi-br-dashboard:before {
    content: "\f165";
}
.fi-br-data-transfer:before {
    content: "\f166";
}
.fi-br-database:before {
    content: "\f167";
}
.fi-br-delete:before {
    content: "\f168";
}
.fi-br-diamond:before {
    content: "\f169";
}
.fi-br-dice:before {
    content: "\f16a";
}
.fi-br-diploma:before {
    content: "\f16b";
}
.fi-br-disk:before {
    content: "\f16c";
}
.fi-br-doctor:before {
    content: "\f16d";
}
.fi-br-document-signed:before {
    content: "\f16e";
}
.fi-br-document:before {
    content: "\f16f";
}
.fi-br-dollar:before {
    content: "\f170";
}
.fi-br-download:before {
    content: "\f171";
}
.fi-br-duplicate:before {
    content: "\f172";
}
.fi-br-e-learning:before {
    content: "\f173";
}
.fi-br-earnings:before {
    content: "\f174";
}
.fi-br-edit-alt:before {
    content: "\f175";
}
.fi-br-edit:before {
    content: "\f176";
}
.fi-br-envelope:before {
    content: "\f177";
}
.fi-br-euro:before {
    content: "\f178";
}
.fi-br-exclamation:before {
    content: "\f179";
}
.fi-br-expand:before {
    content: "\f17a";
}
.fi-br-eye-crossed:before {
    content: "\f17b";
}
.fi-br-eye-dropper:before {
    content: "\f17c";
}
.fi-br-eye:before {
    content: "\f17d";
}
.fi-br-feather:before {
    content: "\f17e";
}
.fi-br-file-add:before {
    content: "\f17f";
}
.fi-br-file-ai:before {
    content: "\f180";
}
.fi-br-file-check:before {
    content: "\f181";
}
.fi-br-file-delete:before {
    content: "\f182";
}
.fi-br-file-eps:before {
    content: "\f183";
}
.fi-br-file-gif:before {
    content: "\f184";
}
.fi-br-file-music:before {
    content: "\f185";
}
.fi-br-file-psd:before {
    content: "\f186";
}
.fi-br-file:before {
    content: "\f187";
}
.fi-br-fill:before {
    content: "\f188";
}
.fi-br-film:before {
    content: "\f189";
}
.fi-br-filter:before {
    content: "\f18a";
}
.fi-br-fingerprint:before {
    content: "\f18b";
}
.fi-br-flag:before {
    content: "\f18c";
}
.fi-br-flame:before {
    content: "\f18d";
}
.fi-br-flip-horizontal:before {
    content: "\f18e";
}
.fi-br-folder-add:before {
    content: "\f18f";
}
.fi-br-folder:before {
    content: "\f190";
}
.fi-br-following:before {
    content: "\f191";
}
.fi-br-football:before {
    content: "\f192";
}
.fi-br-form:before {
    content: "\f193";
}
.fi-br-forward:before {
    content: "\f194";
}
.fi-br-ftp:before {
    content: "\f195";
}
.fi-br-gallery:before {
    content: "\f196";
}
.fi-br-glasses:before {
    content: "\f197";
}
.fi-br-globe-alt:before {
    content: "\f198";
}
.fi-br-globe:before {
    content: "\f199";
}
.fi-br-golf:before {
    content: "\f19a";
}
.fi-br-graduation-cap:before {
    content: "\f19b";
}
.fi-br-graphic-tablet:before {
    content: "\f19c";
}
.fi-br-grid-alt:before {
    content: "\f19d";
}
.fi-br-grid:before {
    content: "\f19e";
}
.fi-br-gym:before {
    content: "\f19f";
}
.fi-br-headphones:before {
    content: "\f1a0";
}
.fi-br-headset:before {
    content: "\f1a1";
}
.fi-br-heart:before {
    content: "\f1a2";
}
.fi-br-home:before {
    content: "\f1a3";
}
.fi-br-hourglass-end:before {
    content: "\f1a4";
}
.fi-br-hourglass:before {
    content: "\f1a5";
}
.fi-br-ice-skate:before {
    content: "\f1a6";
}
.fi-br-id-badge:before {
    content: "\f1a7";
}
.fi-br-inbox:before {
    content: "\f1a8";
}
.fi-br-incognito:before {
    content: "\f1a9";
}
.fi-br-indent:before {
    content: "\f1aa";
}
.fi-br-infinity:before {
    content: "\f1ab";
}
.fi-br-info:before {
    content: "\f1ac";
}
.fi-br-interactive:before {
    content: "\f1ad";
}
.fi-br-interlining:before {
    content: "\f1ae";
}
.fi-br-interrogation:before {
    content: "\f1af";
}
.fi-br-italic:before {
    content: "\f1b0";
}
.fi-br-jpg:before {
    content: "\f1b1";
}
.fi-br-key:before {
    content: "\f1b2";
}
.fi-br-keyboard:before {
    content: "\f1b3";
}
.fi-br-label:before {
    content: "\f1b4";
}
.fi-br-laptop:before {
    content: "\f1b5";
}
.fi-br-lasso:before {
    content: "\f1b6";
}
.fi-br-layers:before {
    content: "\f1b7";
}
.fi-br-layout-fluid:before {
    content: "\f1b8";
}
.fi-br-letter-case:before {
    content: "\f1b9";
}
.fi-br-life-ring:before {
    content: "\f1ba";
}
.fi-br-line-width:before {
    content: "\f1bb";
}
.fi-br-link:before {
    content: "\f1bc";
}
.fi-br-lipstick:before {
    content: "\f1bd";
}
.fi-br-list-check:before {
    content: "\f1be";
}
.fi-br-list:before {
    content: "\f1bf";
}
.fi-br-location-alt:before {
    content: "\f1c0";
}
.fi-br-lock-alt:before {
    content: "\f1c1";
}
.fi-br-lock:before {
    content: "\f1c2";
}
.fi-br-magic-wand:before {
    content: "\f1c3";
}
.fi-br-makeup-brush:before {
    content: "\f1c4";
}
.fi-br-marker-time:before {
    content: "\f1c5";
}
.fi-br-marker:before {
    content: "\f1c6";
}
.fi-br-medicine:before {
    content: "\f1c7";
}
.fi-br-megaphone:before {
    content: "\f1c8";
}
.fi-br-menu-burger:before {
    content: "\f1c9";
}
.fi-br-menu-dots-vertical:before {
    content: "\f1ca";
}
.fi-br-menu-dots:before {
    content: "\f1cb";
}
.fi-br-microphone:before {
    content: "\f1cc";
}
.fi-br-minus-small:before {
    content: "\f1cd";
}
.fi-br-minus:before {
    content: "\f1ce";
}
.fi-br-mobile:before {
    content: "\f1cf";
}
.fi-br-mode-landscape:before {
    content: "\f1d0";
}
.fi-br-mode-portrait:before {
    content: "\f1d1";
}
.fi-br-money:before {
    content: "\f1d2";
}
.fi-br-mouse:before {
    content: "\f1d3";
}
.fi-br-music-alt:before {
    content: "\f1d4";
}
.fi-br-music:before {
    content: "\f1d5";
}
.fi-br-network-cloud:before {
    content: "\f1d6";
}
.fi-br-network:before {
    content: "\f1d7";
}
.fi-br-notebook:before {
    content: "\f1d8";
}
.fi-br-opacity:before {
    content: "\f1d9";
}
.fi-br-package:before {
    content: "\f1da";
}
.fi-br-paint-brush:before {
    content: "\f1db";
}
.fi-br-palette:before {
    content: "\f1dc";
}
.fi-br-paper-plane:before {
    content: "\f1dd";
}
.fi-br-password:before {
    content: "\f1de";
}
.fi-br-pause:before {
    content: "\f1df";
}
.fi-br-pencil:before {
    content: "\f1e0";
}
.fi-br-pharmacy:before {
    content: "\f1e1";
}
.fi-br-physics:before {
    content: "\f1e2";
}
.fi-br-picture:before {
    content: "\f1e3";
}
.fi-br-ping-pong:before {
    content: "\f1e4";
}
.fi-br-play-alt:before {
    content: "\f1e5";
}
.fi-br-play:before {
    content: "\f1e6";
}
.fi-br-playing-cards:before {
    content: "\f1e7";
}
.fi-br-plus-small:before {
    content: "\f1e8";
}
.fi-br-plus:before {
    content: "\f1e9";
}
.fi-br-poker-chip:before {
    content: "\f1ea";
}
.fi-br-portrait:before {
    content: "\f1eb";
}
.fi-br-pound:before {
    content: "\f1ec";
}
.fi-br-power:before {
    content: "\f1ed";
}
.fi-br-presentation:before {
    content: "\f1ee";
}
.fi-br-print:before {
    content: "\f1ef";
}
.fi-br-protractor:before {
    content: "\f1f0";
}
.fi-br-pulse:before {
    content: "\f1f1";
}
.fi-br-quote-right:before {
    content: "\f1f2";
}
.fi-br-rec:before {
    content: "\f1f3";
}
.fi-br-receipt:before {
    content: "\f1f4";
}
.fi-br-rectangle-horizontal:before {
    content: "\f1f5";
}
.fi-br-rectangle-panoramic:before {
    content: "\f1f6";
}
.fi-br-rectangle-vertical:before {
    content: "\f1f7";
}
.fi-br-redo:before {
    content: "\f1f8";
}
.fi-br-reflect:before {
    content: "\f1f9";
}
.fi-br-refresh:before {
    content: "\f1fa";
}
.fi-br-resize:before {
    content: "\f1fb";
}
.fi-br-resources:before {
    content: "\f1fc";
}
.fi-br-rewind:before {
    content: "\f1fd";
}
.fi-br-rocket:before {
    content: "\f1fe";
}
.fi-br-rotate-right:before {
    content: "\f1ff";
}
.fi-br-rugby:before {
    content: "\f200";
}
.fi-br-scale:before {
    content: "\f201";
}
.fi-br-school-bus:before {
    content: "\f202";
}
.fi-br-school:before {
    content: "\f203";
}
.fi-br-scissors:before {
    content: "\f204";
}
.fi-br-screen:before {
    content: "\f205";
}
.fi-br-search-alt:before {
    content: "\f206";
}
.fi-br-search:before {
    content: "\f207";
}
.fi-br-settings-sliders:before {
    content: "\f208";
}
.fi-br-settings:before {
    content: "\f209";
}
.fi-br-share:before {
    content: "\f20a";
}
.fi-br-shield-check:before {
    content: "\f20b";
}
.fi-br-shield-exclamation:before {
    content: "\f20c";
}
.fi-br-shield-interrogation:before {
    content: "\f20d";
}
.fi-br-shield-plus:before {
    content: "\f20e";
}
.fi-br-shield:before {
    content: "\f20f";
}
.fi-br-shop:before {
    content: "\f210";
}
.fi-br-shopping-bag-add:before {
    content: "\f211";
}
.fi-br-shopping-bag:before {
    content: "\f212";
}
.fi-br-shopping-cart-add:before {
    content: "\f213";
}
.fi-br-shopping-cart-check:before {
    content: "\f214";
}
.fi-br-shopping-cart:before {
    content: "\f215";
}
.fi-br-shuffle:before {
    content: "\f216";
}
.fi-br-sign-in:before {
    content: "\f217";
}
.fi-br-sign-out:before {
    content: "\f218";
}
.fi-br-signal-alt-1:before {
    content: "\f219";
}
.fi-br-signal-alt-2:before {
    content: "\f21a";
}
.fi-br-signal-alt:before {
    content: "\f21b";
}
.fi-br-skateboard:before {
    content: "\f21c";
}
.fi-br-smartphone:before {
    content: "\f21d";
}
.fi-br-soap:before {
    content: "\f21e";
}
.fi-br-spa:before {
    content: "\f21f";
}
.fi-br-speaker:before {
    content: "\f220";
}
.fi-br-spinner-alt:before {
    content: "\f221";
}
.fi-br-spinner:before {
    content: "\f222";
}
.fi-br-square-root:before {
    content: "\f223";
}
.fi-br-square:before {
    content: "\f224";
}
.fi-br-star:before {
    content: "\f225";
}
.fi-br-stats:before {
    content: "\f226";
}
.fi-br-stethoscope:before {
    content: "\f227";
}
.fi-br-sticker:before {
    content: "\f228";
}
.fi-br-stop:before {
    content: "\f229";
}
.fi-br-stopwatch:before {
    content: "\f22a";
}
.fi-br-subtitles:before {
    content: "\f22b";
}
.fi-br-surfing:before {
    content: "\f22c";
}
.fi-br-sword:before {
    content: "\f22d";
}
.fi-br-syringe:before {
    content: "\f22e";
}
.fi-br-tablet:before {
    content: "\f22f";
}
.fi-br-target:before {
    content: "\f230";
}
.fi-br-tennis:before {
    content: "\f231";
}
.fi-br-test-tube:before {
    content: "\f232";
}
.fi-br-test:before {
    content: "\f233";
}
.fi-br-text-check:before {
    content: "\f234";
}
.fi-br-text:before {
    content: "\f235";
}
.fi-br-thumbs-down:before {
    content: "\f236";
}
.fi-br-thumbs-up:before {
    content: "\f237";
}
.fi-br-ticket:before {
    content: "\f238";
}
.fi-br-time-add:before {
    content: "\f239";
}
.fi-br-time-check:before {
    content: "\f23a";
}
.fi-br-time-delete:before {
    content: "\f23b";
}
.fi-br-time-fast:before {
    content: "\f23c";
}
.fi-br-time-forward-sixty:before {
    content: "\f23d";
}
.fi-br-time-forward-ten:before {
    content: "\f23e";
}
.fi-br-time-forward:before {
    content: "\f23f";
}
.fi-br-time-half-past:before {
    content: "\f240";
}
.fi-br-time-oclock:before {
    content: "\f241";
}
.fi-br-time-past:before {
    content: "\f242";
}
.fi-br-time-quarter-past:before {
    content: "\f243";
}
.fi-br-time-quarter-to:before {
    content: "\f244";
}
.fi-br-time-twenty-four:before {
    content: "\f245";
}
.fi-br-tool-crop:before {
    content: "\f246";
}
.fi-br-tool-marquee:before {
    content: "\f247";
}
.fi-br-tooth:before {
    content: "\f248";
}
.fi-br-transform:before {
    content: "\f249";
}
.fi-br-trash:before {
    content: "\f24a";
}
.fi-br-treatment:before {
    content: "\f24b";
}
.fi-br-trophy:before {
    content: "\f24c";
}
.fi-br-umbrella:before {
    content: "\f24d";
}
.fi-br-underline:before {
    content: "\f24e";
}
.fi-br-undo:before {
    content: "\f24f";
}
.fi-br-unlock:before {
    content: "\f250";
}
.fi-br-upload:before {
    content: "\f251";
}
.fi-br-usb-drive:before {
    content: "\f252";
}
.fi-br-user-add:before {
    content: "\f253";
}
.fi-br-user-delete:before {
    content: "\f254";
}
.fi-br-user-remove:before {
    content: "\f255";
}
.fi-br-user-time:before {
    content: "\f256";
}
.fi-br-user:before {
    content: "\f257";
}
.fi-br-users:before {
    content: "\f258";
}
.fi-br-vector-alt:before {
    content: "\f259";
}
.fi-br-vector:before {
    content: "\f25a";
}
.fi-br-video-camera:before {
    content: "\f25b";
}
.fi-br-volleyball:before {
    content: "\f25c";
}
.fi-br-volume:before {
    content: "\f25d";
}
.fi-br-wifi-alt:before {
    content: "\f25e";
}
.fi-br-world:before {
    content: "\f25f";
}
.fi-br-yen:before {
    content: "\f260";
}
.fi-br-zoom-in:before {
    content: "\f261";
}
.fi-br-zoom-out:before {
    content: "\f262";
}
